.online-video-downloader {
  display: flex;
  position: relative;
  justify-content: center;
  //   align-items: center;
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;

  background-color: #08003a;
  color: white;
  .container-loading {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    min-height: 100vh;
    overflow-y: hidden;
    z-index: 99;
    .cover {
      position: absolute;
      background-color: gray;
      opacity: 0.75;
      width: 100%;
      height: 100%;
    }
  }
  .container {
    position: relative;
    width: 80%;
    section {
      //   min-height: 500px;

      h1 {
        width: 100%;
        text-align: center;
        font-size: 2rem;
        margin-top: 100px;
      }
      .container-input {
        display: flex;
        justify-content: center;
        align-items: center;
        input {
          width: 50%;
          height: 3rem;
          padding-left: 20px;
          padding-right: 20%;
          border-radius: 7px;
          border: transparent;
        }
        button {
          position: absolute;
          right: 15%;
        }
      }
      h4 {
        text-align: center;
      }
      .container-icon-platform {
        display: flex;
        justify-content: center;
        .icon-platform {
          width: 48px;
          height: 48px;
        }
      }

      .icon-adjust-right {
        margin-right: 5px;
      }
      .icon-adjust-left {
        margin-left: 15px;
      }
      ul {
        list-style-type: none;
        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px;
          border-bottom: gray 1px solid;
          span {
            font-weight: bold;
          }
          p {
            display: flex;
            align-items: center;
          }
          &:nth-child(1) {
            border-top: 3px solid #e25553;
          }
        }
      }
      .container-video-info {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 200px;
          height: auto;
        }
      }
      h5 {
        text-align: center;
      }
    }
  }
}

/* CSS */
.button-62 {
  background: linear-gradient(to bottom right, #ef4765, #ff9a5a);
  border: 0;
  border-radius: 12px;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 2.5;
  outline: transparent;
  padding: 0 1rem;
  text-align: center;
  text-decoration: none;
  transition: box-shadow 0.2s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
}

.button-62:not([disabled]):focus {
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.5), -0.125rem -0.125rem 1rem rgba(239, 71, 101, 0.5), 0.125rem 0.125rem 1rem rgba(255, 154, 90, 0.5);
}

.button-62:not([disabled]):hover {
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.5), -0.125rem -0.125rem 1rem rgba(239, 71, 101, 0.5), 0.125rem 0.125rem 1rem rgba(255, 154, 90, 0.5);
}

/* CSS */
.button-24 {
  background: #ff4742;
  border: 1px solid #ff4742;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  text-align: center;
  font-family: nunito, roboto, proxima-nova, "proxima nova", sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 16px;
  min-height: 40px;
  outline: 0;
  padding: 12px 14px;
  text-align: center;
  text-rendering: geometricprecision;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
}

.button-24:hover,
.button-24:active {
  background-color: initial;
  background-position: 0 0;
  color: #ff4742;
}

.button-24:active {
  opacity: 0.5;
}

// *Loading
.shapes-4 {
  width: 40px;
  height: 40px;
  color: #f03355;
  background: conic-gradient(from -45deg at top 20px left 50%, #0000, currentColor 1deg 90deg, #0000 91deg),
    conic-gradient(from 45deg at right 20px top 50%, #0000, currentColor 1deg 90deg, #0000 91deg), conic-gradient(from 135deg at bottom 20px left 50%, #0000, currentColor 1deg 90deg, #0000 91deg),
    conic-gradient(from -135deg at left 20px top 50%, #0000, currentColor 1deg 90deg, #0000 91deg);
  animation: sh4 1.5s infinite cubic-bezier(0.3, 1, 0, 1);
}
@keyframes sh4 {
  50% {
    width: 60px;
    height: 60px;
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
