@import "styles/color";
@import "breakpoints";

.blogs {
  width: 1200px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
}
.container-blogs {
  width: 80%;
  @include breakpoint(md) {
  }
  .header {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    position: relative;
    overflow: hidden;
    h1 {
      width: auto;
      white-space: nowrap;
    }
    .lines {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-left: 10px;
      .line-green {
        width: 40%;
        border: 2px solid $green;
      }
      .line-gray {
        width: 60%;
        border: 2px solid $slate-gray;
      }
    }
  }
  .container-blogs-show {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .card01 {
      margin-top: 3%;
    }
  }
}
.container-children {
  .container-blog {
    width: 100%;
    padding-bottom: 50px;
    display: flex;
    justify-content: center;

    article {
      width: 80%;
      overflow: hidden;
      h1 {
        font-size: 3em;
      }
      .timestamp {
        opacity: 0.5;
      }
      a {
        color: white;
        font-weight: bold;
        text-decoration: underline;
      }
      code {
        color: $red;
      }
    }
  }
}
