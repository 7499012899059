$width-xl: 1200px;
$width-lg: 992px;
$width-md: 768px;
$width-sm: 576px;

// Define your breakpoints
$breakpoints: (
  "sm": 576px,
  "md": 768px,
  "lg": 992px,
  "xl": 1200px,
);

// Define your mixin
@mixin breakpoint($point) {
  @if map-has-key($breakpoints, $point) {
    @media (max-width: map-get($breakpoints, $point)) {
      @content;
    }
  }
}
