@import "breakpoints";
.Portfolio-Brittanychiang {
  .section-about {
    display: flex;
    @include breakpoint(md) {
      display: block;
      .col-50 {
        width: 100%;
      }
    }
    p {
      font-size: var(--fz-md);
    }
    ul {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      list-style-type: none;
      flex-wrap: wrap;
      width: 50%;
      @include breakpoint(xl) {
        width: 100%;
      }
      @include breakpoint(md) {
        width: 93%;
      }
      li {
        width: 40%;
        position: relative;
        margin-bottom: 10px;
        padding-left: 20px;
        font-family: var(--font-mono);
        font-size: var(--fz-sm);
      }
    }
    .author-image-container {
      display: flex;
      justify-content: center;
      width: 70%;
      position: relative;
      height: fit-content;
      margin-left: 15%;
      margin-top: 50px;
      @include breakpoint(xl) {
        width: 90%;
        margin-left: 0;
      }
      @include breakpoint(md) {
        width: 70%;
        margin-left: 15%;
      }
      $author-image-size: 300px;
      @include breakpoint(xl) {
        $author-image-size: 250px;
      }
      @include breakpoint(md) {
      }
      .author-image {
        position: relative;
        width: $author-image-size;
        height: $author-image-size;
        border-radius: 5px;
        z-index: 2;
        animation: fadeIn 1s ease-in;
        @keyframes fadeIn {
          0% {
            opacity: 0;
            margin-top: 25px;
            margin-left: 50px;
          }
          50% {
            opacity: 1;
          }
          100% {
            opacity: 1;
          }
        }
      }
      .image-frame {
        position: absolute;
        width: $author-image-size;
        height: $author-image-size;
        margin-top: 25px;
        margin-left: 50px;
        border: 2px solid var(--green);
        border-radius: 5px;
        z-index: 0;
      }
      &:hover {
        .image-frame {
          margin-top: 15px;
          margin-left: 35px;
          transition: var(--transition);
        }
      }
    }
  }
}
