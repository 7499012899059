@import "styles/color";

.card01 {
  width: 300px;
  height: 350px;
  overflow: hidden;
  border-radius: 2px;
  background-color: $outer-space;
  color: white;
  position: relative;

  a {
    &:hover {
      h3 {
        color: $green;
        transition: ease-in-out 0.5s;
      }
    }
  }

  img {
    width: 100%;
    height: 170px;
    object-fit: cover;
  }
  .card01-container {
    width: 90%;
    margin-left: 5%;
    overflow: hidden;
    .card01-category {
      color: $red;
      font-weight: bold;
      margin-top: 5px;
      margin-bottom: 5px;
    }
    h3 {
      margin-top: 0;
      color: white;
      transition: ease-in-out 0.5s;
    }
    p {
      color: $platinum;
    }
  }
}
