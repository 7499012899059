@import "styles/color";

.container-layout {
  width: 100%;
  min-height: 100vh;
  background-color: $eerie-black;
  color: white;

  .container-children {
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    main {
      max-width: 1200px;
    }
  }
}
