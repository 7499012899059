$dark-green: #264653;
$green: #2a9d8f;
$yellow: #e9c46a;
$orange: #f4a261;
$red: #e76f51;

// $charcoal: #264653ff;
// $persian-green: #2a9d8fff;
// $saffron: #e9c46aff;
// $sandy-brown: #f4a261ff;
// $burnt-sienna: #e76f51ff;

$cherry-blossom-pink: #edafb8ff;
$champagne-pink: #f7e1d7ff;
$timberwolf: #dedbd2ff;
$ash-gray: #b0c4b1ff;
$outer-space: #4a5759ff;

$seasalt: #f8f9faff;
$antiflash-white: #e9ecefff;
$platinum: #dee2e6ff;
$french-gray: #ced4daff;
$french-gray-2: #adb5bdff;
$slate-gray: #6c757dff;
$outer-space: #495057ff;
$onyx: #343a40ff;
$eerie-black: #212529ff;
