@import "breakpoints";

.Portfolio-Brittanychiang {
  .nav-portfolio-20230223 {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100px;
    font-family: var(--font-mono);
    font-size: var(--fz-sm);
    position: fixed;
    border-bottom: 1px solid var(--lightest-navy);
    background-color: var(--navy);
    overflow: hidden;
    z-index: 99;
    top: 0;
    @include breakpoint(md) {
      position: fixed;
    }
    .nav-logo {
      margin-left: 2%;
      display: flex;
      align-items: center;
      img {
        width: 50px;
        height: 50px;
        margin-right: 20px;
      }
      h1 {
        font-size: 2em;
        font-family: "Great Vibes", cursive;
      }
    }
    .nav-mobile {
      right: 0 !important;
      transition: var(--transition);
    }
    .nav-links {
      display: block;
      position: absolute;
      right: 0;
      margin-right: 2%;
      @include breakpoint(md) {
        background-color: var(--navy-shadow);
        width: 50%;
        height: 100%;
        top: 0;
        right: -52%;
        position: fixed;
        z-index: 9;
        display: flex;
        transition: var(--transition);
      }
      ol {
        display: flex;
        position: relative;
        justify-content: space-between;
        align-items: center;
        list-style-type: none;
        @include breakpoint(md) {
          flex-direction: column;
          justify-content: space-around;
          width: 100%;
          height: 50%;
          margin-top: 50%;
          .btn-primary {
            margin-right: 15%;
            margin-left: 0;
          }
        }
        li {
          margin-right: 50px;
          a {
            font-weight: bold;
            color: var(--lightest-slate);
            &:hover {
              color: var(--green);
              transition: var(--transition);
            }
          }
        }
      }
    }
  }

  // hamburger menu

  #nav-icon1,
  #nav-icon2,
  #nav-icon3,
  #nav-icon4 {
    width: 40px;
    height: 35px;
    position: relative;
    margin: 50px auto;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
  }

  #nav-icon1 span,
  #nav-icon3 span,
  #nav-icon4 span {
    display: block;
    position: absolute;
    height: 9px;
    width: 100%;
    background: #d3531a;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }

  #nav-icon1 span:nth-child(1) {
    top: 0px;
  }

  #nav-icon1 span:nth-child(2) {
    top: 18px;
  }

  #nav-icon1 span:nth-child(3) {
    top: 36px;
  }

  #nav-icon1.open span:nth-child(1) {
    top: 18px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  #nav-icon1.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
  }

  #nav-icon1.open span:nth-child(3) {
    top: 18px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }

  /* Icon 2 */

  #nav-icon2 {
    cursor: pointer;
    display: none;
    z-index: 99;
    @include breakpoint(md) {
      display: block;
      right: 5%;
      position: fixed;
    }
  }

  #nav-icon2 span {
    display: block;
    position: absolute;
    height: 3px;
    width: 50%;
    background: var(--green);
    opacity: 1;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }

  #nav-icon2 span:nth-child(even) {
    left: 50%;
    border-radius: 0 9px 9px 0;
  }

  #nav-icon2 span:nth-child(odd) {
    left: 0px;
    border-radius: 9px 0 0 9px;
  }

  #nav-icon2 span:nth-child(1),
  #nav-icon2 span:nth-child(2) {
    top: 0px;
  }

  #nav-icon2 span:nth-child(3),
  #nav-icon2 span:nth-child(4) {
    top: 12px;
  }

  #nav-icon2 span:nth-child(5),
  #nav-icon2 span:nth-child(6) {
    top: 24px;
  }

  #nav-icon2.open span:nth-child(1),
  #nav-icon2.open span:nth-child(6) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  #nav-icon2.open span:nth-child(2),
  #nav-icon2.open span:nth-child(5) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  #nav-icon2.open span:nth-child(1) {
    left: 5px;
    top: 7px;
  }

  #nav-icon2.open span:nth-child(2) {
    left: calc(50% - 5px);
    top: 7px;
  }

  #nav-icon2.open span:nth-child(3) {
    left: -50%;
    opacity: 0;
  }

  #nav-icon2.open span:nth-child(4) {
    left: 100%;
    opacity: 0;
  }

  #nav-icon2.open span:nth-child(5) {
    left: 4px;
    top: 18px;
  }

  #nav-icon2.open span:nth-child(6) {
    left: calc(50% - 4px);
    top: 18px;
  }

  /* Icon 3 */

  #nav-icon3 span:nth-child(1) {
    top: 0px;
  }

  #nav-icon3 span:nth-child(2),
  #nav-icon3 span:nth-child(3) {
    top: 18px;
  }

  #nav-icon3 span:nth-child(4) {
    top: 36px;
  }

  #nav-icon3.open span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
  }

  #nav-icon3.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  #nav-icon3.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  #nav-icon3.open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
  }

  /* Icon 4 */

  #nav-icon4 {
  }

  #nav-icon4 span:nth-child(1) {
    top: 0px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  #nav-icon4 span:nth-child(2) {
    top: 18px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  #nav-icon4 span:nth-child(3) {
    top: 36px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  #nav-icon4.open span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: -3px;
    left: 8px;
  }

  #nav-icon4.open span:nth-child(2) {
    width: 0%;
    opacity: 0;
  }

  #nav-icon4.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 39px;
    left: 8px;
  }
}
