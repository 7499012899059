@import "styles/color";

.container-layout {
  footer {
    display: flex;
    justify-content: center;
    align-items: center;
    // border-top: 3px solid $green;
    height: 100px;
    margin: 0 !important;
    padding: 0;
    background-color: $outer-space;
    position: absolute;
  }
}
