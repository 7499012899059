.animate-fade-in {
  animation-name: animate-fade-in-keyframes;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes animate-fade-in-keyframes {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
