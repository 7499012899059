@import "breakpoints";
.Portfolio-Brittanychiang {
  .section-blogs {
    width: 100%;
    .blogs-container {
      display: flex;
      flex-wrap: wrap;
      margin-top: 50px;
      justify-content: space-between;
      article {
        width: 32%;
        background-color: var(--light-navy);
        margin-bottom: 2%;
        border-radius: 5px;
        padding-bottom: 30px;
        cursor: pointer;
        &:hover {
          transform: translateY(-10px);
          transition: var(--transition);
          h2 {
            color: var(--green);
          }
          .icon-box {
            color: var(--green);
          }
        }
        @include breakpoint(xl) {
          width: 49%;
        }
        @include breakpoint(sm) {
          width: 90%;
        }
        .blog-item {
          width: 80%;
          margin-left: 10%;
          .top {
            position: relative;
            height: 50px;
            .icon-link {
              position: absolute;
              right: 0;
              margin-top: 20px;
            }
            .icon-box {
              width: 40px;
              height: 40px;
              margin-top: 20px;
            }
          }
          h2 {
            font-size: var(--fz-xl);
            @include breakpoint(lg) {
              font-size: var(--fz-lg);
            }
            color: var(--white);
            height: 50px;
            display: flex;
            align-items: center;
          }
          p {
            font-size: var(--fz-md);
          }
          .tags {
            display: flex;
            font-family: var(--font-mono);
            font-size: var(--fz-sm);
            span {
              margin-right: 5%;
            }
          }
        }
      }
    }
    .container-center {
      margin-top: 25px;
    }
  }
}
