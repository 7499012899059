body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

* {
  // outline: solid #f00 1px !important;
}

.flex {
  display: flex;
}
.flex-direction-column {
  flex-direction: column;
}
.justify-content-center {
  justify-content: center;
}
.align-items-center {
  align-items: center;
}
