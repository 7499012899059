@import "breakpoints";
.Portfolio-Brittanychiang {
  .section-projects {
    header {
      width: 70%;
      @include breakpoint(md) {
        width: 100%;
      }
    }
    .project-theme {
      display: flex;
      align-items: center;
      position: relative;
      width: 100%;
      min-height: 300px;
      margin: 100px auto;
      overflow: hidden;

      .image-container {
        display: block;
        position: absolute;
        width: 60%;
        @include breakpoint(md) {
          width: 100%;
        }
        img {
          width: 100%;
        }
        z-index: 0;
      }
      .project-info {
        display: block;
        position: relative;
        z-index: 1;
        width: 60%;
        @include breakpoint(md) {
          width: 100%;
          text-align: left;
          margin-left: 0 !important;
          background-color: var(--navy);
          background-color: var(--navy-shadow);
          padding: 10%;
        }
        a {
          text-decoration: none;
          color: var(--lightest-slate);
          &:hover {
            color: var(--blue);
          }
        }
        h3 {
          color: var(--green);
          font-size: var(--fz-md);
          font-weight: normal;
          font-family: var(--font-mono);
          margin: 0;
          padding: 0;
        }
        h1 {
          color: var(--lightest-slate);
          margin-top: 5px;
          margin-bottom: 20px;
        }
        .box {
          padding: 10px 20px;
          font-size: var(--fz-md);
          border-radius: 5px;
          background-color: var(--light-navy);

          @include breakpoint(md) {
            background-color: transparent;
            padding: 0;
          }
        }
        .tags {
          display: flex;
          font-size: var(--fz-sm);
          font-family: var(--font-mono);
        }
        .links {
          .link {
            .icon {
              color: var(--white);
              cursor: pointer;
              transition: var(--transition);
              &:hover {
                color: var(--green);
              }
            }
          }
        }
      }
    }
    .type-right {
      text-align: right;
      .project-info {
        margin-left: 40%;
        .tags {
          margin: 20px auto;
          justify-content: right;
          @include breakpoint(md) {
            justify-content: left;
          }
          span {
            margin-left: 5%;
            opacity: 0.5;
            @include breakpoint(md) {
              margin-left: 0;
              margin-right: 5%;
            }
          }
        }
        .links {
          .link {
            .icon {
              margin-left: 5%;
              @include breakpoint(md) {
                margin-left: 0;
                margin-right: 5%;
              }
            }
          }
        }
      }
    }
    .type-left {
      text-align: left;
      .image-container {
        margin-left: 40%;
      }
      .project-info {
        .tags {
          margin: 20px auto;
          justify-content: left;
          span {
            margin-right: 5%;
            opacity: 0.5;
          }
        }
        .links {
          .link {
            .icon {
              margin-right: 5%;
            }
          }
        }
      }
    }

    .tools-container {
      width: 100%;
      margin-top: 50px;
      border-top: 1px solid var(--light-navy);
      border-bottom: 1px solid var(--light-navy);
      padding-bottom: 50px;
      h3 {
        text-align: center;
        width: 90%;
      }
      .container {
        display: flex;
        justify-content: space-around;
        align-items: center;
      }
      a {
        text-decoration: none !important;
        color: white;
      }
      .tool {
        display: flex;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
        padding: 10px 10px 10px 20px;
        transition: background-color 0.5s ease-in-out;
        width: 200px;
        height: 200px;

        &:hover {
          outline: 1px solid var(--light-navy);
          border-radius: 10px;
          background-color: var(--light-navy);
          box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
          color: var(--green);
        }
        .icon-container {
          width: 100%;
          display: flex;
          justify-content: center;
        }
        .tool-icon {
          $size: 75px;
          width: $size;
          height: $size;
        }
        h3 {
          width: 100%;
        }
      }
    }
  }
}
